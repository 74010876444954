export const apiUrl = "https://villaline2.azurewebsites.net";
// export const apiUrl = "https://villaline2-dev.azurewebsites.net";

export const api_Url = "https://openapi.villa-marketjp.com";

export const server = {
    TOKEN_KEY: "token",
    ACCEESS_TOKEN: "accessToken",
    ACCEESS_EXPIRED: "expire",
    EXPIRED_SECONDS: 60,
    USER_INFO: "userInfo"
};