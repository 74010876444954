import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import "material-design-icons-iconfont/dist/material-design-icons.css"
import vuetify from "@/plugins/vuetify";
import i18n from "@/services/i18n";
// import VueCryptojs from "vue-cryptojs"

// Vue.use(VueCryptojs);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount("#app");