<template>
  <div id="app"></div>
</template>

<script>
import { server } from "@/services/constants";

export default {
  name: "DivChat",
  // components: {
  // },
  mounted() {
    const phone_id = this.phoneId;
    if (phone_id != "") {
      const ccScript = document.createElement('script');
      ccScript.setAttribute("src", "https://cc-stg.villa-marketjp.com/js/chat_stg.js");
      ccScript.setAttribute("type", "text/javascript");
      ccScript.setAttribute("contact_id", "3");
      ccScript.setAttribute("cc_id", "1");
      ccScript.setAttribute("social_id", "1515b6cb-cf8e-4113-a6a8-ca1b7d3b0e0d");
      ccScript.setAttribute("phone_id", phone_id);
      ccScript.setAttribute("name", this.userName);
      document.head.appendChild(ccScript);
    }
  },
  computed: {
    phoneId() {
      const phone_id = localStorage.getItem(server.TOKEN_KEY);
      if (phone_id==null || phone_id.toString().trim()=="")
          return "";
      return phone_id;
    },
    userName() {
        const userInfo = JSON.parse(localStorage.getItem('userInfo') ?? {});
        if (userInfo?.tm_firstname ?? "" != "")
          return userInfo?.tm_firstname ?? "";
        return userInfo?.tm_name ?? "";
    }
  }
};
</script>