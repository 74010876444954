import Vue from "vue";
import Vuex from "vuex";
import api from "@/services/api_villa";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLogged: false,
    accessToken: "",
    accessExpire: 0,
    expireIn: 60
  },

  getters: {
    isLogin(state) {
      return state.isLogged;
    },
    accessToken(state) {
      return state.accessToken;
    },
    accessExpire(state) {
      return state.accessExpire;
    },
    expireIn(state) {
      return state.expireIn;
    }
  },

  mutations: {
    SET_LOGGED_IN(state){
      state.isLogged = true;
    },
    SET_LOGGED_OUT(state) {
      state.isLogged = false;
    },
    SET_ACCESS_TOKEN(state, value) {
      state.accessToken = value;
    },
    SET_ACCESS_EXPIRE(state, value) {
      state.accessExpire = value;
    },
    SET_EXPIRE_IN(state, value) {
      state.expireIn = value;
    }
  },

  actions: {
    async doLogin({ commit, dispatch }, { username, password }) {
      if (await api.login(username, password)) {
        commit("SET_LOGGED_IN");
      } else {
        dispatch("doLogout");
      }
    },
    async doLogout({ commit }) {
      commit("SET_LOGGED_OUT");
      await api.logoff();
    },
    async restoreLogin({ commit }) {
      if (await api.isLoggedIn()) {
        commit("SET_LOGGED_IN");
      }
    }
  },
  
  modules: {},
});
