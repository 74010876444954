<template>
  <v-app>
    <Content/>
    <!-- <Div3cx v-if="$store.state.isLogged"/> -->
    <DivChat v-if="$store.state.isLogged"/>
    <GoTop />
  </v-app>
</template>

<script>
import Content from "@/components/Content"
import GoTop from "@/components/GoTop";
import DivChat from "@/components/DivChat"
// import Div3cx from "@/components/Div3cx"
// import { server } from "@/services/constants";
// import cc from "@/services/chat/chat";

export default {
  name: "App",
  // data() {
  //   return {
  //     url_widget: "https://front-cs-dev.villa-marketjp.com/widget",
  //   };
  // },
  components: {
    Content,
    GoTop,
    DivChat
    // Div3cx
  },
  // methods: {
  //   async loadJS(FILE_URL, async = true) {
  //     let scriptEle = document.createElement("script");

  //     scriptEle.setAttribute("src", FILE_URL);
  //     scriptEle.setAttribute("type", "text/javascript");
  //     scriptEle.setAttribute("async", async);

  //     document.body.appendChild(scriptEle);

  //     // success event 
  //     scriptEle.addEventListener("load", () => {
  //       console.log("File loaded")
  //     });
  //     // error event
  //     scriptEle.addEventListener("error", (ev) => {
  //       console.log("Error on loading file", ev);
  //     });
  //   }
  // },
  // async mounted() {
  //   const token = localStorage.getItem(server.TOKEN_KEY);
  //   const isLogged = !(token==null || token.toString().trim()=="");
  //   alert(isLogged)
  //   if (isLogged) {
  //     await this.loadJS('./services/chat/chat.js', false);
  //     // let ccScript = document.createElement('script')
  //     // ccScript.setAttribute('src', '@/services/chat/chat.js')
  //     // // ccScript.id = "chat-js"
  //     // document.body.appendChild(ccScript)
  //     // document.window.reload();
  //   }
  //   // await this.$store.dispatch({ type: "restoreLogin" });

  //   // let recaptchaScript = document.createElement('script')
  //   // recaptchaScript.setAttribute('src', 'https://downloads-global.3cx.com/downloads/livechatandtalk/v1/callus.js')
  //   // // recaptchaScript.setAttribute('src', 'https://cdn.3cx.com/livechat/v1/callus.js')
  //   // recaptchaScript.id = "tcx-callus-js"
  //   // document.head.appendChild(recaptchaScript)

  //   // let div3cx = document.createElement('div')
  //   // div3cx.innerHTML = '<call-us-selector phonesystem-url="https://villamarket.3cx.co" party="LiveChat609511"></call-us-selector>'
  //   // document.body.appendChild(div3cx)
  // }
};
</script>