import Vue from "vue";
import VueRouter from "vue-router";
// import { checkLogin, checkLoginWithRedirect } from "@/services/api_villa";
import { checkLogin } from "@/services/api_villa";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    meta: { keepAlive: false }
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/Register.vue"),
    meta: { keepAlive: false }
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    component: () => import("@/views/ForgotPassword.vue"),
    meta: { keepAlive: false }
  },
  // {
  //   path: "/apitest",
  //   name: "ApiTest",
  //   component: () => import("@/views/ApiTest.vue"),
  //   beforeEnter(to, from, next) { checkLogin(to, from, next) },
  //   meta: { keepAlive: false }
  // },
  {
    path: "/home",
    name: "Home",
    component: () => import("@/views/Home.vue"),
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/rewards",
    name: "Rewards",
    component: () => import("@/views/Rewards.vue"),
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/history",
    name: "History",
    component: () => import("@/views/History.vue"),
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/redeem/ecomatcher",
    name: "RedeemEcomatcher",
    component: () => import("@/views/Redeem/Ecomatcher/Index.vue"),
    meta: { keepAlive: false }
  },
  {
    path: "/redeem/ecomatcher/redeem/:docno/:refno",
    name: "RedeemEcomatcherRedeem",
    component: () => import("@/views/Redeem/Ecomatcher/Redeem.vue"),
    // beforeEnter(to, from, next) { checkLoginWithRedirect(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/",
    // redirect: "/home"
    // name: "Home2",
    component: () => import("@/views/Home.vue"),
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("@/views/Terms.vue"),
    meta: { keepAlive: false }
  },
  {
    path: "*",
    // name: "Home3",
    component: () => import("@/views/Home.vue"),
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
    // component: () => import("@/views/Login.vue"),
    // beforeEnter(to, from, next) { checkLogin(to, from, next) },
    // meta: { keepAlive: false }
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next)=>{
//   if ( to.name !== 'login' && !this.isloggedin ){
//     next({
//       path: 'login',
//       replace: true
//     })
//   } else {
//     next();
//   }
// })

export default router;
