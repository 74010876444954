<template>
  <v-main>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"/>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"/>
  </v-main>
</template>

<script>
export default {
  
}
</script>

<style>

</style>