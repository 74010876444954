import axios from "axios";
import router from "@/router";
import store from "@/store";
import { server, apiUrl } from "@/services/constants";

export const checkLogin = async (to, from, next) => {
    try {
        await store.dispatch({ type: "restoreLogin" });
        if (store.state.isLogged) {
            return next();
        }
        else {
            return next("/login");
        }

        // let token = localStorage.getItem(server.TOKEN_KEY);
        // let token = localStorage.getItem(server.TOKEN_KEY);
        // if (token==null || token.toString().trim()=="") {
        //     console.log("-- checkLogin -- 222");
        //     return next("/login");
        // }
        // else {
        //     console.log("-- checkLogin -- 333");
        //     store.commit("SET_LOGGED_IN");
        //     return next();
        // }
    } catch (error) {
        return next("/login");
    }
};

export const checkLoginWithRedirect = async (to, from, next) => {
    try {
        await store.dispatch({ type: "restoreLogin" });
        if (store.state.isLogged) {
            return next();
        }
        else {
            return next(`/login?redirect=${window.location.pathname}`);
        }
    } catch (error) {
        return next("/login");
    }
};

const isLoggedIn = async () => {
    let token = localStorage.getItem(server.TOKEN_KEY);
    // console.log('-- isLoggedIn --', token);
    if (token==null || token.toString().trim()=="") {
        // router.push("/login", () => {});
        return false;
    }
    else {
        // let result = refreshUserInfo(token);
        // return result;
        return true;
    };

    // let username = localStorage.getItem(server.USER_NAME);
    // let token = localStorage.getItem(server.TOKEN_KEY);
    // if ((token==null || token.trim()=="") && (username==null || username.trim()=="")) {
    //     router.push("/login");
    // }
    // else {
    //     if (username==null || username.trim()=="") {
    //         localStorage.setItem(server.USER_NAME, token);
    //     }
    //     return true;
    // }
};

async function logoff() {
    localStorage.removeItem(server.TOKEN_KEY);
    localStorage.removeItem(server.ACCEESS_TOKEN);
    localStorage.removeItem(server.ACCEESS_EXPIRED);
    localStorage.removeItem(server.EXPIRED_SECONDS);
    localStorage.removeItem(server.USER_INFO);
    router.push("/login", () => {});
};

const getExpireTime = (sec) => {
    const t = new Date();
    if (sec > 0)
        t.setSeconds(t.getSeconds() + sec);
    return parseInt(t / 1000); //utc datetime
}

const getInstanceNotAuthen = async () => {
    const instance = axios.create({
        baseURL: `${apiUrl}`,
        headers: {'Content-Type': `application/json`},
        timeout: 10000
    });
    return instance;
};

const refreshToken = async () => {
    // console.log('-- refresh token --');
    const instance = await getInstanceNotAuthen();
    let payload = {};

    var result = false;
    await instance
    .post("/signup", payload)
    .then(response => {
        // console.log(response.data);
        // console.log("-- expireIn --", this.$store.state.expireIn);
        // this.router.store.commit("accessToken", response.data.token);
        // console.log("-- accessToken --", this.$store.state.accessToken);

        // store.getters.accessToken = response.data.token;
        // store.commit("accessToken", response.data.token);
        // store.commit("accessExpire", getExpireTime(server.EXPIRED_SECONDS - 5));
        // store.commit("expireIn", getExpireTime(server.EXPIRED_SECONDS));

        localStorage.setItem(server.ACCEESS_TOKEN, response.data.token);
        localStorage.setItem(server.ACCEESS_EXPIRED, getExpireTime(server.EXPIRED_SECONDS - 5));
        return true;
    })
    .catch(error => {
        console.log(error);
    })
    return result;
};

const getToken = async () => {
    // console.log("-- expireIn --", store.getters.expireIn);
    // // // this.router.store.commit("accessToken", response.data.token);
    // console.log("-- accessToken --", store.getters.accessToken);

    var d1 = localStorage.getItem(server.ACCEESS_EXPIRED) ?? 0;
    if (d1 == null)
        d1 = 0;
    var d2 = getExpireTime(0);
    if (d2 > d1)
        await refreshToken();
    return localStorage.getItem(server.ACCEESS_TOKEN) ?? "";
};

const getInstance = async () => {
    const key = await getToken();
    const token = Buffer.from(key)
    const instance = axios.create({
        baseURL: `${apiUrl}`,
        headers: {'Authorization': `Bearer ${token}`, 'Content-Type': `application/json`},
        timeout: 10000
    });
    return instance;
};

const login = async (phoneNumber, password) => {
    const instance = await getInstance();
    let payload = {
        "action": "LOGIN",
        "phone": phoneNumber,
        "password": password
    };

    var result = false;
    await instance
    .post("api/web/check_member", payload)
    .then(response => {
        // console.log(response.data);
        result = response.data.res_code=="000";
        if (result)
            localStorage.setItem(server.TOKEN_KEY, response.data.phone_id);
        else {
            localStorage.setItem(server.TOKEN_KEY, "");
            if (response.data.res_code=="001")
                throw response.data.res_desc;
        }
    })
    .catch(error => {
        // console.log(error);
        throw error;
    })
    return result;
};

// const getUserInfo = async (phoneNumber) => {
//     const instance = await getInstance();
//     let payload = {
//         "tmmobile1": phoneNumber
//     };

//     var result = null;
//     await instance
//     .post("api/check_point", payload)
//     .then(response => {
//         // console.log(response.data);
//         const mobile1 = response.data?.Mobile1 ?? "";
//         if (mobile1 == null || mobile1 == "")
//             result = null
//         else {
//             // response.data["tm_email"] = "somkid.dev@gmail.com";
//             result = JSON.stringify(response.data);
//         }
//     })
//     .catch(error => {
//         console.log(error);
//     })
//     return result;
// };

const refreshUserInfo = async (phoneNumber) => {
    const instance = await getInstance();
    let payload = {
        "tmmobile1": phoneNumber
    };

    var result = false;
    await instance
    .post("api/check_point", payload)
    .then(response => {
        // console.log(response.data);
        // response.data["Mobile1"] = "";
        result = response.data.res_code=="000";
        if (result) {
            let data = JSON.stringify(response.data);
            localStorage.setItem('userInfo', data);
            result = true;
        }
        // const mobile1 = response.data?.Mobile1 ?? "";
        // if (mobile1 == null || mobile1 == "")
        //     result = false
        // else
        //     result = true;
    })
    .catch(error => {
        console.log(error);
    })
    return result;
};

const getMember = async (docno, refno) => {
    const instance = await getInstance();
    let payload = {
        "docno": docno,
        "refno": refno,
        "type": "Tree"
    };

    var result = null;
    await instance
    .post("api/getmember", payload)
    .then(response => {
        // console.log(response.data);
        result = response.data;
    })
    .catch(error => {
        console.log(error);
    })
    return result;
};

const getBanner = async (phoneNumber) => {
    const instance = await getInstance();
    let payload = {
        "action": "S",
        "userid": phoneNumber
    };

    var result = null;
    await instance
    .post("api/banner", payload)
    .then(response => {
        // console.log(JSON.stringify(response.data));
        // result = JSON.stringify(response.data);
        result = response.data;
    })
    .catch(error => {
        console.log(error);
    })
    return result;
};

const getCoupon = async (phoneNumber) => {
    const instance = await getInstance();
    let payload = {
        "action": "COUPONGDETAILS",
        "phone_no": phoneNumber
    };

    var result = null;
    await instance
    .post("api/coupon", payload)
    .then(response => {
        // console.log(JSON.stringify(response.data));
        // result = JSON.stringify(response.data);
        result = response.data;
    })
    .catch(error => {
        console.log(error);
    })
    return result;
};

const isRegister = async (phoneNumber) => {
    const instance = await getInstance();
    let payload = {
        "action": "CHPHONE",
        "phone": phoneNumber
    };

    var result = true;
    await instance
    .post("api/web/check_member", payload)
    .then(response => {
        // alert(JSON.stringify(response.data));
        result = response.data.res_code!="000";
    })
    .catch(error => {
        console.log(error);
    })
    return result;
};

const register = async (phoneNumber, password, tm_firstname, tm_lastname) => {
    const instance = await getInstance();
    let payload = {
        "action": "REGISTER",
        "phone": phoneNumber,
        "password": password,
        "tm_firstname": tm_firstname,
        "tm_lastname": tm_lastname
    };

    var result = false;
    await instance
    .post("api/web/check_member", payload)
    .then(response => {
        // console.log(response.data);
        result = response.data.res_code=="000";
    })
    .catch(error => {
        console.log(error);
    })
    return result;
};

const redeem_trees = async (tm_code, tm_name, email, number_of_trees, point, docno, refno) => {
    const instance = await getInstance();
    let payload = {
        "tmcode": tm_code,
        "point": parseInt(point)*-1,
        "docno": docno,
        "refno": refno,
        "type": "Tree",
        // "tmmobile1": localStorage.getItem(server.TOKEN_KEY) ?? "",
        "number_of_trees": number_of_trees,
        "number_of_links": 1,
        "name": tm_name,
        "Email": email,
        "lang": (localStorage.getItem('lang') ?? "en").toUpperCase()
    };

    var result = false;
    await instance
    .post("api/redeem_trees", payload)
    .then(response => {
        // console.log(response.data);
        result = response.data.res_code=="000";
        if (result)
            return result;
        else
            throw response.data.res_desc;
    })
    .catch(error => {
        console.log(error);
    })
    return result;
};

const test_redeem_trees = async (name, email) => {
    const instance = await getInstance();
    let payload = {
        "tmcode": "30010",
        "point": -1,
        "tmmobile1": "phone-id-B3167931-164D-4183-A455-E448E26426D1081120221123220618276222E8B5822D-6408-4C78-B0C9-8FEE99519A9B",
        "number_of_trees": 1,
        "number_of_links": 1,
        "name": name,
        "Email": email,
        "lang": (localStorage.getItem('lang') ?? "en").toUpperCase()
    };

    var result = false;
    await instance
    .post("api/test/redeem_trees", payload)
    .then(response => {
        // console.log(response.data);
        result = response.data.res_code=="000";
        return result;
    })
    .catch(error => {
        console.log(error);
    })
    return result;
};

// const addPhone = async (phoneNumber) => {
//     const instance = await getInstance();
//     let payload = {
//         "phoneno": phoneNumber,
//         "type": "Tree"
//     };

//     var result = null;
//     await instance
//     .post("api/web/addphone", payload)
//     .then(response => {
//         // console.log(response.data);
//         result = response.data;
//     })
//     .catch(error => {
//         console.log(error);
//     })
//     return result;
// };

const sendLinkRedeem = async (phoneNumber) => {
    const instance = await getInstance();
    let payload = {
        "phoneno": phoneNumber,
        "type": "Tree"
    };

    var result = false;
    await instance
    .post("api/send/redeem", payload)
    .then(response => {
        // alert(JSON.stringify(response.data));
        result = response.data.res_code==200;
    })
    .catch(error => {
        console.log(error);
    })
    return result;
};

const getOtp = async (phoneNumber, login_type='') => {
    const instance = await getInstance();
    let payload = {
        "action": "GET_OTP",
        "data_t01": phoneNumber,
        "data_t02": localStorage.getItem('lang') ?? "en",
        "data_t03": login_type
    };
    
    var result = false;
    await instance
    .post("api/otp", payload)
    .then(response => {
        // console.log(response);
        let r = response.data[0];
        if (r != null) {
            result = r.err == "N";
            if (!result)
                throw r.msg;
        }
    })
    .catch(error => {
        console.log(error);
        throw error;
    })
    return result;
};

const checkOtp = async (phoneNumber, otp) => {
    const instance = await getInstance();
    let payload = {
        "action": "CHECK_OTP",
        "data_t01": phoneNumber,
        "data_t02": otp
    };

    var result = false;
    await instance
    .post("api/otp", payload)
    .then(response => {
        // console.log(response);
        let r = response.data[0];
        if (r != null) {
            result = r.err == "N";
        }
        // let r = response?.data?.data[0];
        // if (r != null) {
        //     result = r.err == "N";
        // }
    })
    .catch(error => {
        console.log(error);
    })
    return result;
};

//******************** */
// function getInstance1 () {
//     const key = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJoZWxsbyI6IndvcmxkIiwiaWF0IjoxNjU3MDgzNTU1fQ.wCvIbSC3l2wa_vzH4JKzX1OAvwIBZCLc6LXUEacNpZg";
//     const url = "https://villaline2.azurewebsites.net/api"
//     const token = Buffer.from(key)
//     const instance = axios.create({
//         baseURL: url,
//         headers: {'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${token}`, 'Content-Type': `application/json`},
//         timeout: 10000
//     });
//     return instance;
// };

// const isRegister = async (phoneNumber) => {
//     const instance = getInstance1();
//     let payload = {
//         "action": "CHPHONE",
//         "phone": phoneNumber
//     };

//     var result = true;
//     await instance
//     .post("/web/check_member", payload)
//     .then(response => {
//         // alert(JSON.stringify(response.data));
//         result = response.data.res_code!="000";
//     })
//     .catch(error => {
//         console.log(error);
//     })
//     return result;
// };

// const register = async (phoneNumber, password) => {
//     const instance = getInstance1();
//     let payload = {
//         "action": "REGISTER",
//         "phone": phoneNumber,
//         "password": password
//     };

//     var result = false;
//     await instance
//     .post("/web/check_member", payload)
//     .then(response => {
//         // console.log(response.data);
//         result = response.data.res_code=="000";
//     })
//     .catch(error => {
//         console.log(error);
//     })
//     return result;
// };

// const login = async (phoneNumber, password) => {
//     const instance = getInstance1();
//     let payload = {
//         "action": "LOGIN",
//         "phone": phoneNumber,
//         "password": password
//     };

//     var result = false;
//     await instance
//     .post("/web/check_member", payload)
//     .then(response => {
//         // console.log(response.data);
//         result = response.data.res_code=="000";
//     })
//     .catch(error => {
//         console.log(error);
//     })
//     return result;
// };

// const getUserInfo = async (phoneNumber) => {
//     const instance = getInstance1();
//     let payload = {
//         "tmmobile1": phoneNumber
//     };

//     var result = null;
//     await instance
//     .post("/check_point", payload)
//     .then(response => {
//         // console.log(JSON.stringify(response.data));
//         result = JSON.stringify(response.data);
//     })
//     .catch(error => {
//         console.log(error);
//     })
//     return result;
// };

// const getBanner = async () => {
//     const instance = getInstance1();
//     let payload = {
//         "action": "S"
//     };

//     var result = null;
//     await instance
//     .post("/banner", payload)
//     .then(response => {
//         // console.log(JSON.stringify(response.data));
//         // result = JSON.stringify(response.data);
//         result = response.data;
//     })
//     .catch(error => {
//         console.log(error);
//     })
//     return result;
// };

// const getCoupon = async (phoneNumber) => {
//     const instance = getInstance1();
//     let payload = {
//         "action": "COUPONGDETAILS",
//         "phone_no": phoneNumber
//     };

//     var result = null;
//     await instance
//     .post("/coupon", payload)
//     .then(response => {
//         // console.log(JSON.stringify(response.data));
//         // result = JSON.stringify(response.data);
//         result = response.data;
//     })
//     .catch(error => {
//         console.log(error);
//     })
//     return result;
// };

//******************** */
// function getInstance2 () {
//     const key = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJoZWxsbyI6IndvcmxkIiwiaWF0IjoxNjU1ODA1NjI5fQ.vHPfNG34ZvcS1PXYCKZq9viPWXu46aGpYbzkpdMag6Y";
//     const url = "https://villaline2.azurewebsites.net/api"
//     const token = Buffer.from(key)
//     const instance = axios.create({
//         baseURL: url,
//         headers: {'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${token}`, 'Content-Type': `application/json`},
//         // headers: {'Authorization': `${token}`, 'Content-Type': `application/json`},
//         timeout: 10000
//     });
//     return instance;
// };

// const getOtp = async (phoneNumber) => {
//     const instance = getInstance2();
//     let payload = {
//         "action": "GET_OTP",
//         "data_t01": phoneNumber,
//         "data_t02": localStorage.getItem('lang') ?? "en"
//     };
    
//     var result = false;
//     await instance
//     .post("/otp", payload)
//     .then(response => {
//         // console.log(response);
//         let r = response.data[0];
//         if (r != null) {
//             result = r.err == "N";
//         }
//     })
//     .catch(error => {
//         console.log(error);
//     })
//     return result;
// };

// const checkOtp = async (phoneNumber, otp) => {
//     const instance = getInstance2();
//     let payload = {
//         "action": "CHECK_OTP",
//         "data_t01": phoneNumber,
//         "data_t02": otp
//     };

//     var result = false;
//     await instance
//     .post("/otp", payload)
//     .then(response => {
//         // console.log(response);
//         let r = response.data[0];
//         if (r != null) {
//             result = r.err == "N";
//         }
//         // let r = response?.data?.data[0];
//         // if (r != null) {
//         //     result = r.err == "N";
//         // }
//     })
//     .catch(error => {
//         console.log(error);
//     })
//     return result;
// };

//******************** */
export default {
    // refreshToken,
    isLoggedIn,
    logoff,
    isRegister,
    register,
    login,
    // getUserInfo,
    refreshUserInfo,
    getMember,
    getBanner,
    getCoupon,
    redeem_trees,
    test_redeem_trees,
    // addPhone,
    sendLinkRedeem,
    getOtp,
    checkOtp
};